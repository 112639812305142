// src/api/services/userService.ts
import apiClient from '../apiClient';
import { UserInfo, UserToken } from '#/entity';
import { AxiosResponse } from 'axios';

export interface SignInReq {
  username: string;
  password: string;
}

export interface SignUpReq extends SignInReq {
  email: string;
}

export type SignInRes = UserToken & { user: UserInfo };

export enum UserApi {
  SignIn = '/auth/signin',
  SignUp = '/auth/signup',
  Logout = '/auth/logout',
  Refresh = '/auth/refresh',
  User = '/user',
}

// src/api/services/userService.ts
// src/api/services/userService.ts

const signin = async (data: SignInReq): Promise<AxiosResponse<SignInRes>> => {
  try {
    const response = await apiClient.post<SignInRes>({ url: UserApi.SignIn, data });
    console.log('Réponse API SignIn après appel :', response);
    return response;
  } catch (error) {
    console.error('Erreur dans signin :', error);
    throw error;
  }
};

const signup = async (data: SignUpReq): Promise<AxiosResponse<SignInRes>> => {
  const response = await apiClient.post<SignInRes>({ url: UserApi.SignUp, data });
  console.log('Réponse API SignUp :', response);
  return response;
};

const logout = async (): Promise<void> => {
  await apiClient.get({ url: UserApi.Logout });
};

const findById = async (id: string): Promise<AxiosResponse<UserInfo>> => {
  const response = await apiClient.get<UserInfo>({ url: `${UserApi.User}/${id}` });
  console.log(`Données utilisateur récupérées pour ID ${id} :`, response);
  return response;
};

export default {
  signin,
  signup,
  findById,
  logout,
};
