// src/router/routes/modules/jefcoav.tsx

import { Suspense, lazy } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { SvgIcon } from '@/components/icon';
import { CircleLoading } from '@/components/loading';

import { AppRouteObject } from '#/router';

const JefcoavDashboard = lazy(() => import('@/pages/jefcoav/dashboard'));
const JefcoavManagement = lazy(() => import('@/pages/jefcoav/management'));
const JefcoavClients = lazy(() => import('src/pages/jefcoav/clients')); //mettre users au lieu de client pour que ca marche en local je sais pas poruqoi

const jefcoav: AppRouteObject = {
  order: 7,
  path: 'jefcoav',
  element: (
    <Suspense fallback={<CircleLoading />}>
      <Outlet />
    </Suspense>
  ),
  meta: {
    label: 'sys.menu.jefcoav',
    icon: <SvgIcon icon="ic_folder" className="ant-menu-item-icon" size="24" />,
    key: '/jefcoav',
  },
  children: [
    {
      index: true,
      element: <Navigate to="dashboard" replace />,
    },
    {
      path: 'dashboard',
      element: <JefcoavDashboard />,
      meta: { label: 'sys.menu.jefcoav.dashboard', key: '/jefcoav/dashboard' },
    },
    {
      path: 'management',
      element: <JefcoavManagement />,
      meta: { label: 'sys.menu.jefcoav.management', key: '/jefcoav/management' },
    },
    {
      path: 'clients',
      element: <JefcoavClients />,
      meta: { label: 'sys.menu.jefcoav.clients', key: '/jefcoav/clients' },
    },
  ],
};

export default jefcoav;
