// src/components/Logo.tsx
import { NavLink } from 'react-router-dom';
/*import { useThemeToken } from '@/theme/hooks';*/
import logo from '@/assets/images/logo.png';

interface Props {
  size?: number | string;
}

function Logo({ size = 60 }: Props) {
  /*const { colorPrimary } = useThemeToken();*/

  return (
    <NavLink to="/" style={{ display: 'inline-flex', alignItems: 'center' }}>
      <img
        src={logo}
        alt="Logo"
        style={{
          width: size,
          height: 'auto',
          maxHeight: size,
          objectFit: 'contain',
          /*filter: `drop-shadow(0 0 2px ${colorPrimary})`,*/
        }}
      />
    </NavLink>
  );
}

export default Logo;
