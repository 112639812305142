import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { getStringItem } from '@/utils/storage';

import en_US from './lang/en_US';
import fr_FR from './lang/fr_FR';

import { LocalEnum, StorageEnum } from '#/enum';

const defaultLng = getStringItem(StorageEnum.I18N) || (LocalEnum.fr_FR as string);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: defaultLng,
    fallbackLng: LocalEnum.fr_FR,
    resources: {
      en_US: { translation: en_US },
      fr_FR: { translation: fr_FR },
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
