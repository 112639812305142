// src/store/userStore.ts
import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { useNavigate } from 'react-router-dom';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

import userService, { SignInReq, SignInRes } from '@/api/services/userService';
import { UserInfo, UserToken } from '#/entity';
import { StorageEnum } from '#/enum';
import { AxiosResponse } from 'axios';

const HOMEPAGE = '/jefcoav/dashboard';

type UserStore = {
  userInfo: Partial<UserInfo>;
  userToken: UserToken;
  actions: {
    setUserInfo: (userInfo: UserInfo) => void;
    setUserToken: (token: UserToken) => void;
    clearUserInfoAndToken: () => void;
  };
};

const useUserStore = create<UserStore>()(
  persist(
    (set) => ({
      userInfo: {},
      userToken: {},
      actions: {
        setUserInfo: (userInfo) => {
          set({ userInfo });
        },
        setUserToken: (userToken) => {
          set({ userToken });
        },
        clearUserInfoAndToken() {
          set({ userInfo: {}, userToken: {} });
        },
      },
    }),
    {
      name: 'userStore',
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({
        [StorageEnum.UserInfo]: state.userInfo,
        [StorageEnum.UserToken]: state.userToken,
      }),
    },
  ),
);

export const useUserInfo = () => useUserStore((state) => state.userInfo);
export const useUserToken = () => useUserStore((state) => state.userToken);
export const useUserPermission = () => useUserStore((state) => state.userInfo.permissions || []);
export const useUserActions = () => useUserStore((state) => state.actions);

export const useSignIn = () => {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const { setUserToken, setUserInfo } = useUserActions();

  const signInMutation = useMutation<AxiosResponse<SignInRes>, Error, SignInReq>({
    mutationFn: userService.signin,
  });

  const signIn = async (data: SignInReq) => {
    try {
      console.log('Début de la fonction signIn...');
      console.log('Données de connexion :', data);

      const res = await signInMutation.mutateAsync(data);
      console.log('Réponse brute du serveur après signInMutation :', res);

      // Accéder aux données réelles dans res.data
      const responseData = res.data;

      if (!responseData || typeof responseData !== 'object' || !responseData.user || !responseData.accessToken) {
        console.error('Données retournées par signin :', responseData);
        throw new Error('Réponse invalide de l\'API');
      }

      const { user, accessToken } = responseData;
      console.log('Utilisateur reçu :', user);
      console.log('Token d\'accès reçu :', accessToken);

      const userPermissions = user.role?.permissions || [];
      console.log('Permissions utilisateur :', userPermissions);

      setUserToken({ accessToken, refreshToken: '' });
      setUserInfo({ ...user, permissions: userPermissions });

      console.log('HOMEPAGE :', HOMEPAGE);
      navigate(HOMEPAGE || '/', { replace: true });
      console.log('Redirection vers la page d\'accueil.');
    } catch (err: any) {
      console.error('Erreur lors de la connexion :', err);

      message.warning({
        content: err.message || 'Erreur lors de la connexion',
        duration: 3,
      });
    }
  };

  return signIn;
};

export default useUserStore;
