// src/main.tsx
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Analytics } from '@vercel/analytics/react';
import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import 'virtual:svg-icons-register';
import { HelmetProvider } from 'react-helmet-async';

import App from '@/App';

// i18n
import './locales/i18n';
// tailwind css
import './theme/index.css';

const charAt = `
    █████╗ ██╗     ██╗     ██╗ ██████╗ ███████╗ 
   ██╔══██╗██║     ██║     ██║██╔═══██╗██╔════╝ 
   ███████║██║     ██║     ██║██║   ██║███████╗ 
   ██╔══██║██║     ██║     ██║██║   ██║╚════██║ 
   ██║  ██║███████╗███████╗██║╚██████╔╝███████║ 
   ╚═╝  ╚═╝╚══════╝╚══════╝╚═╝ ╚═════╝ ╚══════╝
`;

console.info(`%c${charAt}`, 'color: #5BE49B');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      gcTime: 300_000,
      staleTime: 10_000,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <HelmetProvider>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Suspense>
        <Analytics />
        <App />
      </Suspense>
    </QueryClientProvider>
  </HelmetProvider>,
);
