// src/api/apiClient.ts

import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import useUserStore from '@/store/userStore';

interface ApiErrorResponse {
  message?: string;
  [key: string]: any;
}

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_APP_BASE_API,
  timeout: 50000,
  headers: { 'Content-Type': 'application/json;charset=utf-8' },
});

// Interceptor for requests
axiosInstance.interceptors.request.use(
  (config) => {
    const token = useUserStore.getState().userToken?.accessToken;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    console.log('Requête envoyée avec config :', config);
    return config;
  },
  (error) => {
    console.error('Erreur lors de l\'envoi de la requête :', error);
    return Promise.reject(error);
  }
);

// Interceptor for responses
axiosInstance.interceptors.response.use(
  (response: AxiosResponse<any, any>) => {
    console.log('Réponse complète de l\'API :', response);
    return response; // Return the full response
  },
  (error: AxiosError) => {
    console.error('Erreur dans la réponse de l\'API :', error.response);

    const defaultMessage = 'Erreur lors de la requête API';
    let errMsg = defaultMessage;

    if (error.response?.data && typeof error.response.data === 'object') {
      errMsg = (error.response.data as ApiErrorResponse)?.message || defaultMessage;
    }

    // If 401, clear user info and token
    if (error.response?.status === 401) {
      useUserStore.getState().actions.clearUserInfoAndToken();
    }

    return Promise.reject(new Error(errMsg));
  }
);

class APIClient {
  // Authentication
  async login(credentials: { email: string; password: string }): Promise<void> {
    try {
      console.log('Tentative de connexion avec les identifiants :', credentials);
      const response = await this.post<{ user: any; accessToken: string }>({
        url: '/auth/login',
        data: credentials,
      });
      console.log('Réponse de connexion :', response);
      const { user, accessToken } = response.data; // Access data from response.data
      useUserStore.getState().actions.setUserToken({ accessToken });
      useUserStore.getState().actions.setUserInfo(user);
      console.log('Token et userInfo stockés dans userStore');
    } catch (error) {
      console.error('Erreur lors de la connexion :', error);
      throw error;
    }
  }

  // Users
  async getUsers<T = any>(): Promise<T> {
    try {
      const response = await this.get<T>({ url: '/users' });
      console.log('Données récupérées dans getUsers :', response);
      return response.data; // Return data
    } catch (error) {
      console.error('Erreur dans getUsers :', error);
      throw error;
    }
  }

  async getUserById<T = any>(userId: number): Promise<T> {
    try {
      const response = await this.get<T>({ url: `/users/${userId}` });
      console.log(`Données récupérées pour l'utilisateur ${userId} dans getUserById :`, response);
      return response.data; // Return data
    } catch (error) {
      console.error(`Erreur dans getUserById (${userId}) :`, error);
      throw error;
    }
  }

  async createUser<T = any>(userData: T): Promise<T> {
    try {
      const response = await this.post<T>({ url: '/users', data: userData });
      console.log('Utilisateur créé avec succès dans createUser :', response);
      return response.data; // Return data
    } catch (error) {
      console.error('Erreur dans createUser :', error);
      throw error;
    }
  }

  async updateUser<T = any>(userId: number, userData: T): Promise<T> {
    try {
      const response = await this.put<T>({ url: `/users/${userId}`, data: userData });
      console.log(`Utilisateur ${userId} mis à jour avec succès dans updateUser :`, response);
      return response.data; // Return data
    } catch (error) {
      console.error(`Erreur dans updateUser (${userId}) :`, error);
      throw error;
    }
  }

  async deleteUser(userId: number): Promise<void> {
    try {
      await this.delete<void>({ url: `/users/${userId}` });
      console.log(`Utilisateur ${userId} supprimé avec succès dans deleteUser`);
    } catch (error) {
      console.error(`Erreur dans deleteUser (${userId}) :`, error);
      throw error;
    }
  }

  // Products
  async getProducts<T = any>(): Promise<T> {
    try {
      const response = await this.get<T>({ url: '/products' });
      console.log('Données des produits récupérées dans getProducts :', response);
      return response.data; // Return data
    } catch (error) {
      console.error('Erreur dans getProducts :', error);
      throw error;
    }
  }

  async getProductById<T = any>(productId: number): Promise<T> {
    try {
      const response = await this.get<T>({ url: `/products/${productId}` });
      console.log(`Données récupérées pour le produit ${productId} dans getProductById :`, response);
      return response.data; // Return data
    } catch (error) {
      console.error(`Erreur dans getProductById (${productId}) :`, error);
      throw error;
    }
  }

  async createProduct<T = any>(productData: T): Promise<T> {
    try {
      const response = await this.post<T>({ url: '/products', data: productData });
      console.log('Produit créé avec succès dans createProduct :', response);
      return response.data; // Return data
    } catch (error) {
      console.error('Erreur dans createProduct :', error);
      throw error;
    }
  }

  async updateProduct<T = any>(productId: number, productData: T): Promise<T> {
    try {
      const response = await this.put<T>({ url: `/products/${productId}`, data: productData });
      console.log(`Produit ${productId} mis à jour avec succès dans updateProduct :`, response);
      return response.data; // Return data
    } catch (error) {
      console.error(`Erreur dans updateProduct (${productId}) :`, error);
      throw error;
    }
  }

  async deleteProduct(productId: number): Promise<void> {
    try {
      await this.delete<void>({ url: `/products/${productId}` });
      console.log(`Produit ${productId} supprimé avec succès dans deleteProduct`);
    } catch (error) {
      console.error(`Erreur dans deleteProduct (${productId}) :`, error);
      throw error;
    }
  }

  // HTTP methods
  async get<T = any>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    try {
      const response = await axiosInstance.request<T>({ ...config, method: 'GET' });
      console.log('Données obtenues dans get :', response);
      return response; // Return the full response
    } catch (error) {
      console.error('Erreur dans la méthode get :', error);
      throw error;
    }
  }

  async post<T = any>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    try {
      const response = await axiosInstance.request<T>({ ...config, method: 'POST' });
      console.log('Données obtenues dans post :', response);
      return response; // Return the full response
    } catch (error) {
      console.error('Erreur dans la méthode post :', error);
      throw error;
    }
  }

  async put<T = any>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    try {
      const response = await axiosInstance.request<T>({ ...config, method: 'PUT' });
      console.log('Données obtenues dans put :', response);
      return response; // Return the full response
    } catch (error) {
      console.error('Erreur dans la méthode put :', error);
      throw error;
    }
  }

  async delete<T = any>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    try {
      const response = await axiosInstance.request<T>({ ...config, method: 'DELETE' });
      console.log('Données obtenues dans delete :', response);
      return response; // Return the full response
    } catch (error) {
      console.error('Erreur dans la méthode delete :', error);
      throw error;
    }
  }

  async uploadImage(productId: number, file: File): Promise<string> {
    try {
      const formData = new FormData();
      formData.append('file', file);

      // Route Allios : Upload lié à un produit spécifique
      const url = `/products/${productId}/upload`;

      const response = await axiosInstance.post<{ imageUrl: string }>(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${useUserStore.getState().userToken?.accessToken}`,
        },
      });

      console.log('Image uploadée via Allios :', response);
      return response.data.imageUrl;
    } catch (error) {
      console.error('Erreur lors de l\'upload via Allios :', error);
      throw error;
    }
  }
}

export default new APIClient();
